import './App.css';
import Work from './assets/Working.svg'
import Logo from './assets/logo.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img alt="Noshi" src={Logo} height="120vh" width="auto"/>
      <h1>Website Under Construction</h1>
      <img alt="Work" src={Work} height="500vh" width="100%"/>
      </header>
    </div>
  );
}

export default App;
